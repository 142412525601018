<template>
 <header id="header">
  <div v-if="modal" id="modal">
    <div class="card card-modal">
      <svg @click="closeModal()" id="close-modal" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 30 30">
        <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"></path>
      </svg>
      <div class="qrcode">
        <img src="../assets/qrcode.png" />
      </div>
      <div class="text-qrcode">
        <button id="btn-copypix" @click="copyPix()">Chave Copia/Cola</button>
        <span>CNPJ: 33.068.410/0001-23</span>
        IGREJA EVANGÉLICA NOVA ALIANÇA EL SHADAY
      </div>
    </div>
  </div>
  <div class="header-container">
   <!-- <div class="menu-mobile">
    <svg viewBox="0 0 100 80" width="40" height="40">
     <rect width="100" height="20"></rect>
     <rect y="30" width="100" height="20"></rect>
     <rect y="60" width="100" height="20"></rect>
    </svg>
   </div> -->
   <a href="/">
    <img id="logo" src="../assets/logo.png" alt="logo elshaday" />
   </a>
   <span class="menu-mobile" @click="openModal()">CONTRIBUA</span>
   <ul>
    <a href="#footer">
     <li>SOBRE NÓS</li>
    </a>
    <a
     href="https://www.youtube.com/@novaaliancaelshaday7179/streams"
     target="_blank"
    >
     <li>MENSAGENS</li>
    </a>
    <a href="#events">
     <li>AGENDA</li>
    </a>
    <li @click="openModal()">CONTRIBUA</li>
   </ul>
  </div>
 </header>
</template>

<script>
export default {
 name: "HeaderVue",
 props: {
  msg: String,
 },
 data() {
  return {
    modal: false
  }
 },
 methods: {
  openModal() {
    document.body.style.overflow = "hidden"
    this.modal = true
  },
  closeModal() {
    document.body.style.overflow = "auto"
    this.modal = false
  },
  copyPix() {
    navigator.clipboard.writeText("00020126360014BR.GOV.BCB.PIX0114330684100001235204000053039865802BR5922Nova Alianca El Shaday6013Imperatriz-MA62070503***6304F4FE")
    this.$emit('toast-success', 'Chave Pix copiada com sucesso!')
  }
 }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#modal {
  position: absolute;
  top: 0;
  width: 100vw;
  max-width: 100%;
  height: 110vh;
  background: rgba(0, 0, 0, 0.86);
  overflow: hidden;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
.card-modal {
  position: relative;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  gap: 20px;
}
.text-qrcode {
  font-weight: 600;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
}
#btn-copypix {
  padding: 8px 15px;
  border-radius: 10px;
  border: solid 1px #2c3e50;
  background: transparent;
}
#close-modal {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
}
header {
 background: #fff;
 padding: 20px 0;
 box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.13);
}
.header-container {
 margin: 0 auto;
 display: flex;
 justify-content: space-between;
 align-items: center;
 max-width: 1080px;
}
#logo {
 width: 120px;
}
header ul {
 font-size: 15px;
 list-style-type: none;
 padding: 0;
 display: flex;
 gap: 20px;
}
header a {
 text-decoration: none !important;
 color: #2c3e50;
}
header li {
 display: inline-block;
 margin: 0 10px;
 cursor: pointer;
 opacity: 0.9;
 transition: all 0.3s ease-in-out;
}
header li:hover {
 opacity: 1;
 color: #3366cc;
}
a {
 color: #42b983;
}
.menu-mobile {
 display: none;
 cursor: pointer;
 font-weight: 600;
 border: solid 2px #2c3e50;
 border-radius: 5px;
 padding: 5px 10px;
 font-size: 16px;
}

@media screen and (max-width: 1110px) {
 .header-container {
  padding: 0px 40px;
 }
}

@media screen and (max-width: 811px) {
 header {
  padding: 10px 0;
 }
 header ul {
  display: none;
 }
 .menu-mobile {
  display: block;
 }
}
@media screen and (max-width: 522px) {
  .qrcode {
    margin-top: 50px;
    width: 700px;
  }
  .qrcode img {
    width: 100%;
  }
}
@media screen and (max-width: 496px) {
 .header-container {
  padding: 0px 15px;
  justify-content: space-between;
 }
}
</style>
