<template>
  <div>
    <Header @toast-success="toastSubmit($event)" />
    <router-view id="content" />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: { Header, Footer },
  data() {
    return {
      icon: {
        success:
          '<span class="material-symbols-outlined">task_alt</span>',
        danger:
          '<span class="material-symbols-outlined">error</span>',
        warning:
          '<span class="material-symbols-outlined">warning</span>',
        info:
          '<span class="material-symbols-outlined">info</span>',
      }
    }
  },
  methods: {
    showToast(
      message = "Sample Message",
      toastType = "info",
      duration = 5000) {
      if (
        !Object.keys(this.icon).includes(toastType))
        toastType = "info";

      let box = document.createElement("div");
      box.classList.add(
        "toast", `toast-${toastType}`);
      box.innerHTML = ` <div class="toast-content-wrapper"> 
                      <div class="toast-icon"> 
                      ${this.icon[toastType]} 
                      </div> 
                      <div class="toast-message">${message}</div> 
                      <div class="toast-progress"></div> 
                      </div>`;
      duration = duration || 5000;
      box.querySelector(".toast-progress").style.animationDuration =
        `${duration / 1000}s`;

      let toastAlready =
        document.body.querySelector(".toast");
      if (toastAlready) {
        toastAlready.remove();
      }

      document.body.appendChild(box)
    },

    toastSubmit(message) {
      this.showToast(message, "success", 5000);
      setTimeout(() => {
        const child = document.querySelector(".toast-success");
        document.body.removeChild(child);
      }, 5000)
    },

    toastInformation() {
      this.showToast("Do POTD and Earn Coins", "info", 5000);
      setTimeout(() => {
        const child = document.querySelector(".toast-info");
        document.body.removeChild(child);
      }, 5000)
    },

    toastFailed() {
      this.showToast("Failed unexpected error", "danger", 5000);
      setTimeout(() => {
        const child = document.querySelector(".toast-danger");
        document.body.removeChild(child);
      }, 5000)
    },

    toastWarn() {
      this.showToast("!warning! server error", "warning", 5000);
      setTimeout(() => {
        const child = document.querySelector(".toast-warning");
        document.body.removeChild(child);
      }, 5000)
    }
  }
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  background: #eaeaea;
}

#content {
  margin: 40px 0;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.card {
  width: 100%;
  max-width: 1080px;
  background: #fff;
  padding: 30px 0px;
  max-width: 1080px;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.13);
  border-radius: 10px;
}

.toast {
  position: fixed;
  top: 50px;
  right: 25px;
  max-width: 300px;
  background: #fff;
  padding: 0.5rem;
  border-radius: 4px;
  box-shadow: -1px 1px 10px rgba(0, 0, 0, 0.3);
  z-index: 999999 !important;
  animation: slideInRight 0.3s ease-in-out forwards,
    fadeOut 0.5s ease-in-out forwards 3s;
  transform: translateX(110%);
}

.toast.closing {
  animation: slideOutRight 0.5s ease-in-out forwards;
}

.toast-progress {
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;
  background: #b7b7b7;
  animation: toastProgress 3s ease-in-out forwards;
}

.toast-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toast-icon {
  padding: 0.35rem 0.5rem;
  font-size: 1.5rem;
}

.toast-message {
  flex: 1;
  font-size: 0.9rem;
  color: #000000;
  padding: 0.5rem;
}

.toast.toast-success {
  background: #95eab8;
}

.toast.toast-success .toast-progress {
  background-color: #2ecc71;
}

.toast.toast-danger {
  background: #efaca5;
}

.toast.toast-danger .toast-progress {
  background-color: #e74c3c;
}

.toast.toast-info {
  background: #bddaed;
}

.toast.toast-info .toast-progress {
  background-color: #3498db;
}

.toast.toast-warning {
  background: #ead994;
}

.toast.toast-warning .toast-progress {
  background-color: #f1c40f;
}

@keyframes slideInRight {
  0% {
    transform: translateX(110%);
  }

  75% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(110%);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes toastProgress {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

@media screen and (max-width: 1110px) {
  #content {
    padding: 0 40px;
  }
}

@media screen and (max-width: 590px) {
  #content {
    padding: 0 20px;
  }
}
</style>
