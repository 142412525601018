import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/tribus",
        name: "Tribus",
        beforeEnter() {
            window.location.replace("https://conferenciatribus.com.br/");
        },
        // redirect: 'https://www.even3.com.br/tribus-2024-paixao-e-devocao-465784/',
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;