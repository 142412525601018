<template>
 <div class="home">
  <CardPicture />
  <div class="card resume">
   <div class="resumo-cultos">
    <p>CULTOS</p>
    <span> Quinta: <b>19:30</b> </span>
    <span> Sábado:</span>
    <span> Aliança Teen <b>17:00</b> </span>
    <span> Aliança Jovem <b>19:30</b> </span>
    <span> Domingo: <b>9:00 | 17:00 | 19:30</b> </span>
   </div>
   <div class="line"></div>
   <div class="proximo-evento">
    <p>PRÓXIMO EVENTO</p>
    <span>6 a 7 de julho</span>
    <b>Encontro de Mulheres (Urbano)</b>
   </div>
   <div class="line"></div>
   <Socials />
  </div>
  <div id="events"></div>
  <CardEvent
   tag="ENCONTROS"
   date="6 a 7 de julho"
   img-src="encontromulheres.jpg"
   title="Encontro de Mulheres (Urbano)"
   link="https://wa.me/5599988133510"
  />
  <CardEvent
   tag="CONFERENCIAS"
   date="15 a 17 de agosto"
   img-src="conferencia.jpg"
   title="Tribus - Paixão & Devoção"
   link="http://conferenciatribus.com.br/"
  />
 </div>
</template>

<script>
import CardPicture from "@/components/CardPicture.vue";
import Socials from "@/components/Socials.vue";
import CardEvent from "@/components/CardEvent.vue";

export default {
 name: "HomeVue",
 components: { CardPicture, Socials, CardEvent },
};
</script>

<style>
.home {
 display: flex;
 flex-direction: column;
 gap: 40px;
 align-items: center;
}
.resume {
 color: #666;
 display: flex;
 justify-content: center;
 gap: 80px;
 align-items: center;
}
.resumo-cultos,
.proximo-evento {
 display: flex;
 flex-direction: column;
 gap: 2px;
 align-items: center;
}
.resumo-cultos p {
    margin-bottom: -2px;
}
.line {
 width: 0.5px;
 height: 70px;
 background: #333;
 opacity: 0.5;
}

@media screen and (max-width: 981px) {
 .resume {
  flex-direction: column;
  gap: 20px;
 }
 .line {
  width: 150px;
  height: 0.5px;
 }
}
</style>